import React from "react"
import "./styles.css"

export const Register = ({ className }) => {
  return (
    <div
      id="register-component"
      className={`component d-flex justify-content-center align-items-center ${className}`}
    >
      <div className="card">
        <div className="card-body">
          <h1>Registre-se</h1>
          <form>
            <label htmlFor="nome" className="form-label">
              Nome:
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite seu nome completo"
            />
            <label htmlFor="email" className="form-label">
              E-mail:
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="mail@mail.com"
            />
            <label htmlFor="senha" className="form-label">
              Senha:
            </label>
            <input type="password" className="form-control" />
            <label htmlFor="confirmacao" className="form-label">
              Confirmação:
            </label>
            <input type="password" className="form-control" />
            <div className="form-check my-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="concordo"
              />
              <label className="form-check-label" htmlFor="concordo">
                Concordo com os Termos, Política de Dados e Política de Cookies.
              </label>
            </div>
            <div className="d-grid my-3">
              <button type="submit" className="btn btn-lg btn-primary">
                Criar minha conta
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
