import { links } from "data"
import { Link } from "gatsby"
import React from "react"
import "./styles.css"

export const Footer = () => (
  <footer>
    <nav className="navbar navbar-expand-md">
      <div className="container">
        <ul className="navbar-nav justify-content-start">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              &copy; Copyright {new Date().getFullYear()}
            </Link>
          </li>
          {links.map(({ name, path }, ix) => {
            return (
              <li className="nav-item" key={ix}>
                <Link className="nav-link" to={path}>
                  {name}
                </Link>
              </li>
            )
          })}
        </ul>
        <ul className="navbar-nav justify-content-end">
          <li className="nav-item mx-2">
            <a
              href="https://www.instagram.com/fabricainfooficial/"
              target="_blank"
            >
              <i className="fa fa-instagram" />
            </a>
          </li>
          <li className="nav-item mx-2">
            <a
              href="https://pt-br.facebook.com/FabricaInfoOficial"
              target="_blank"
            >
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li className="nav-item mx-2">
            <a
              href="https://br.linkedin.com/company/fabricainfooficial"
              target="_blank"
            >
              <i className="fa fa-linkedin" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </footer>
)
