import { graphql, useStaticQuery } from "gatsby"

export const Clients = () => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp(
        filter: { fluid: { originalName: { glob: "cliente*.png" } } }
      ) {
        nodes {
          fluid {
            originalName
            src
          }
        }
      }
    }
  `)

  return {
    bw: allImageSharp.nodes
      .filter(m => m.fluid.originalName.includes("black"))
      .map(m => {
        return { name: m.fluid.originalName, src: m.fluid.src }
      }),
    color: allImageSharp.nodes
      .filter(m => m.fluid.originalName.includes("cor"))
      .map(m => {
        return { name: m.fluid.originalName, src: m.fluid.src }
      }),
  }
}
