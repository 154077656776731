import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.bundle"
import { Footer, Header, Main } from "components"
// import 'font-awesome/scss/font-awesome.scss'
import "font-awesome/css/font-awesome.min.css"
import PropTypes from "prop-types"
import React from "react"
import "./styles.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
