import { links, linksAuth } from "data"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import logo from "images/logo.png"
import React from "react"
import "./styles.css"

export const Header = () => (
  <header className="fixed-top">
    <nav className="navbar navbar-expand-md">
      <div className="container">
        <Link className="navbar-brand" to="/" aria-current="page">
          <img src={logo} alt="logo" className="img-fluid" id="logo-header" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {links.map(({ name, path }, ix) => {
              if (path.includes("#"))
                return (
                  <li className="nav-item" key={ix}>
                    <AnchorLink
                      className="nav-link"
                      to={path}
                      aria-current="page"
                    >
                      {name}
                    </AnchorLink>
                  </li>
                )
              return (
                <li className="nav-item" key={ix}>
                  <Link className="nav-link" to={path} aria-current="page">
                    {name}
                  </Link>
                </li>
              )
            })}
          </ul>
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item">
              <a className="nav-link me-3" href="https://app.godocs.com.br/" target="_blank">Login</a>
            </li>
            <li className="nav-item">
              <a className="btn btn-outline-primary" href="https://app.godocs.com.br/apps/cadastrar/" target="_blank">Registre-se</a>
            </li>
          </ul>
          {/* <ul className="navbar-nav justify-content-end">
            {linksAuth.map(({ name, path, style }, ix) => {
              return (
                <li className="nav-item" key={ix}>
                  <Link className={style ? style : "nav-link me-3"} to={path}>
                    {name}
                  </Link>
                </li>
              )
            })}
          </ul> */}
        </div>
      </div>
    </nav>
  </header>
)
