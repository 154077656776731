import { Link } from "gatsby"
import React from "react"
import "./styles.css"

export const Login = ({ className }) => {
  return (
    <div
      id="login-component"
      className={`component d-flex justify-content-center align-items-center ${className}`}
    >
      <div className="card">
        <div className="card-body">
          <h1>Login</h1>
          <form>
            <label htmlFor="email" className="form-label">
              E-mail:
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="mail@mail.com"
            />
            <label htmlFor="senha" className="form-label">
              Senha:
            </label>
            <input type="password" className="form-control" />
            <div className="d-grid my-3">
              <button type="submit" className="btn btn-lg btn-primary">
                Entrar
              </button>
            </div>
          </form>
          <p className=" text-center generic-label">
            Não tem uma conta?{" "}
            <Link className="orange" to="/registre-se">
              Cadastre-se
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
