export const links = [
  { name: "GoDocs", path: "/" },
  { name: "Clientes", path: "/#clientes" },
  { name: "Quem somos?", path: "/#quem-somos" },
  { name: "Soluções", path: "/#processos" },
]

export const linksAuth = [
  { name: "Login", path: "/login" },
  {
    name: "Registre-se",
    path: "/registre-se",
    style: "btn btn-outline-primary",
  },
]
