import logo from "images/logo-white.png"
import React from "react"

export const LogoHome = ({ className }) => {
  return (
    <div
      id="logo-home-component"
      className={`component d-none d-sm-flex justify-content-center align-items-center ${className}`}
    >
      <img src={logo} alt="logo" title="logo" className="img-fluid" />
    </div>
  )
}
